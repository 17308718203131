import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Benvenuti a tutti nel mio sito web personale <br>
          </br>Questo sito è in costruzione
        </p>
        <a
          className="App-link"
          href="https://github.com/CodePhys"
          target="_blank"
          rel="noopener noreferrer"
        >
          MyGitHub
        </a>
      </header>
    </div>
  );
}

export default App;
